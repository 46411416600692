import React from 'react'

import BasicButton from '../BasicButton/BasicButton'
import InputComponent from '../InputComponent/InputComponent'
import { XMarkIcon } from '@heroicons/react/24/outline'

import { cn } from '@/utils/cn'

// Define an interface for the component's props
type PopupComponentProps = {
    children: React.ReactNode
    setOpen: (open: boolean) => void
    open: boolean
    closeButton?: boolean
    className?: string
    buttonClassName?: string
    modalClassName?: string
    buttonIcon?: React.ReactNode
}

const PopupComponent = ({
    children,
    setOpen,
    open,
    closeButton = true,
    className,
    buttonClassName,
    modalClassName,
    buttonIcon,
}: PopupComponentProps) => {
    return (
        <div
            onClick={(e) => {
                e.stopPropagation()
                setOpen(!open)
            }}
        >
            <InputComponent
                type="checkbox"
                readOnly
                checked={open}
                id="my-modal-5"
                className="modal-toggle"
            />
            <div className={cn('modal backdrop-blur-sm z-[999999]', modalClassName)}>
                <div
                    className={cn(
                        'relative flex flex-col justify-center z-40 min-h-[120px] max-sm:p-2 max-sm:px-0 max-sm:max-h-full modal-box animate-fade-up animate-duration-300 animate-once animate-ease-out bg-white dark:bg-base-100 dark:border-slate-100 dark:border-opacity-25 border-opacity-80 dark:border border-2 border-slate-900 no-scrollbar',
                        className
                    )}
                    onClick={(e) => e.stopPropagation()}
                >
                    {closeButton ? (
                        <BasicButton
                            className={cn(
                                'absolute z-50 cursor-default btn btn-neutral btn-sm btn-circle top-4 right-4',
                                buttonClassName
                            )}
                            onClick={(e) => {
                                e.stopPropagation()
                                setOpen(!open)
                            }}
                        >
                            {buttonIcon || (
                                <XMarkIcon className="w-6 h-6" fill="none" strokeWidth={2} />
                            )}
                        </BasicButton>
                    ) : null}

                    {children}
                </div>
            </div>
        </div>
    )
}

export default PopupComponent
